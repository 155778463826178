import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Seo from '@widgets/Seo'
import QuizComponent from '@widgets/HeroQuiz'



const Quiz = props => {

    return (
        <>
            <Layout {...props}>
                <Seo title='Qualify Now'/>
                <QuizComponent />
            </Layout>
        </>
    )
}

export default Quiz;
